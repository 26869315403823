body {
  background-image: url(/public/images/fondo-corazondefenix-opa.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;

 
 
}

.titulo {

  font-family: 'Georgia, serif';
  font-size: 28px;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  position: absolute; /* Coloca el texto en una posición específica */
  top: 0; /* Alinea al borde superior */
  margin: 0; /* Elimina cualquier margen por defecto */
  padding: 10px; /* Espaciado opcional */

  text-align: center; /* Opcional: centrar el texto horizontalmente */



}
.portada {

  font-family:  'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 28px;
  display: flex;
  color: #0727ad;
  justify-content: center;
  align-items: center;
  font-weight: 400;

}


.contenedor-dos-columnas {
  display: flex; /* Activa el diseño flex */
  /*flex: 4;*/
  justify-content: space-between; /* Espaciado entre las columnas */
  align-items: center; /* Alinea verticalmente las columnas al centro */
  gap: 20px; /* Espaciado entre las columnas */
  margin: 20px; /* Ajusta el espacio del contenedor */
}

.portada {
  /*flex: 10;*/ /* Ocupa el 50% del espacio disponible */
  text-align: center; /* Opcional: alinea el texto a la izquierda */
}

.imagen {
  justify-content: center; /* Centra horizontalmente */
  align-items: flex-start; /* Alinea al top del contenedor */
  text-align: center;
  width: 100%; /* Escala la imagen al ancho del contenedor */
  height: 400px; /* Altura fija o dinámica */
 

  
}


.imagen img {
   width: 80%; /* Escala la imagen al ancho del contenedor */
   height: 80%; /* Escala la imagen al alto del contenedor */
   object-fit: contain; 
}


.App {
  position: relative;
  width: 100%;
  height: 100%;
}

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0727ad;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}

.modal {
  position: fixed;
  bottom: 100px;
  right: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  /*transition: opacity 0.7s ease, transform 0.7s ease;*/
  transition: opacity 0.3s ease; /*, transform 1.3s ease;*/
 /*  transition: opacity 0.1s ease, transform 1.8s ease;*/
  background: #fff;
  border-radius: 8px; /* Bordes redondeados del modal */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.modal-header {
  background-color: rgb(111,111,111);
  color: #fff; /* Texto blanco */
  border-radius: 8px 8px 0 0; /* Bordes redondeados solo en la parte superior */
}

.modal-title {
  margin: 0;
  padding: 15px; /* Espaciado interno */
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: left; /* Centrar el título */
}

.modal-subtitle {
  margin: 0;
  padding: 10px 15px; /* Espaciado interno */
  font-size: 14px;
  font-weight: 300;
  text-align: center; /* Centrar subtítulo */
  text-align: justify;
  color:#000;
  background-color:#c1c3c4;
}


.modal.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  padding:1px
}

.modal h2 {
  margin: 0;
  font-size: 18px;
  color: #ffffff;
}



.modal form {
  margin-top: 10px;
  padding: 10px;
}

.modal form input{
  width: 100%;
  /*margin-bottom: 10px;*/
  padding-top: 10px;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 30px;
}

.modal form textarea {
  width: 100%;

  padding-top: 17px;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100px;
}

.modal form button {
  width: 100%;
  padding: 10px;
  background-color: #070707;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal form button:hover {
  background-color: #6d6f70;
}

/* Contenedor del input y label */


.form-group.hidden {
  opacity: 0; /* Lo hace invisible */
  /*transform: scale(0.95);*/ /* Opcional: añade un efecto de contracción */
  pointer-events: none; /* Evita la interacción mientras desaparece */
}

.form-group {
  margin-bottom: 20px;
  position: relative;
  /*transition: opacity 0.1s ease, transform 0.1s ease;*/ /* Transiciones suaves */
}

/* Estilo del contenedor del input */
.input-container {
  position: relative;
}

/* Input */
.input-field {
  width: 95%;
  /*padding: 12px 10px;*/
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  background: #fff;
  margin-left: -3px;
  /*transition: border-color 0.3s ease;*/
  font-family: Arial, Helvetica, sans-serif;
}

/* Cambiar color del borde al hacer focus */
.input-field:focus {
  border-color: #0d0d0e;
}

/* Label */
.input-label {
  position: absolute;
  top: 50%;
  left: 5px;
  font-size: 16px;
  color: #999;
  transform: translateY(-50%);
  pointer-events: none; /* Hace el label no interactuable */
  transition: all 0.2s ease-in-out;
}

/* Cuando el input tiene texto o está en focus */
.input-field:focus + .input-label,
.input-field:not(:placeholder-shown) + .input-label {
  top: 10px;
  left: 5px;
  font-size: 10px;
  color: #0d0d0e;
}

/* Estilo del botón de envío */
button[type="submit"] {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.overlay {
  position: absolute; /* Posiciona el overlay dentro del contenedor */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo semitransparente */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Superpón al formulario */
  text-align: center;
}

.overlay h3 {
  margin: 0;
  font-size: 1.5rem;
}

.overlay p {
  margin-top: 10px;
  font-size: 1rem;
}